import React, { useEffect, useRef, useState } from "react";
import {
  AnimatedCircle,
  BreathBot,
  BreathCon,
  BreathLast,
  BreathMid,
  BreathTop,
  BreathWra,
  ChillImg,
  ContentHolder,
  CrossCon,
  CrossIco,
  Dis,
  FlatButton,
  FlatLeft,
  FlatRight,
  RButton,
  RHeader,
  RSubHeader,
  RWrap,
  RtMiddle,
  RtReg,
  RtRegBt,
  RtRegBu,
  RtRegCon,
  RtRegImg,
  RtRegSu,
  RtRegSub,
  RtRegUb,
  RtTop,
  RtTopimg,
  TimmerCon,
} from "./Breath.elements";
import Chill from "../../assets/chill.png";
import Cross from "../../assets/cross.png";
import AudioIcon from "../../assets/audio.png";
import Phone from "../../assets/phone.png";
import { Link, useNavigate } from "react-router-dom";

const Modal = ({ isOpen, onClose, onRestart, onNavigate }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        zIndex: 1000,
      }}
    >
      <h2>Session Ended</h2>
      <p>Would you like to start another session or explore more?</p>
      <button onClick={onRestart}>Restart Session</button>
      <button onClick={onNavigate}>Explore More</button>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

const Breath = () => {
  const [timeLeft, setTimeLeft] = useState(60);
  const [breathing, setBreathing] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const audioRef = useRef(
    new Audio("https://alsallum.s3.eu-north-1.amazonaws.com/calm.mp3")
  );

  useEffect(() => {
    let intervalId;
    if (sessionStarted && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev === 1) {
            setShowModal(true);
            clearInterval(intervalId); // Stop the interval
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (timeLeft === 0) {
      setIsPlaying(false);
      audioRef.current.pause();
    }
    return () => clearInterval(intervalId);
  }, [sessionStarted, timeLeft]);

  useEffect(() => {
    let breathingInterval;
    if (sessionStarted) {
      breathingInterval = setInterval(() => {
        setBreathing((prevBreathing) =>
          prevBreathing === "شهيق" ? "زفير" : "شهيق"
        );
      }, 4000);
    }
    return () => clearInterval(breathingInterval);
  }, [sessionStarted]);

  const startSession = () => {
    setSessionStarted(true);
    setIsPlaying(true);
    audioRef.current.play();
    setBreathing("شهيق");
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const restartSession = () => {
    setTimeLeft(60);
    setSessionStarted(true);
    setIsPlaying(true);
    setShowModal(false);
    audioRef.current.play();
  };

  const exploreMore = () => {
    navigate("/extra"); // Use navigate to redirect
  };

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <BreathWra>
      <BreathCon>
        <BreathTop>
          <Link to={"/extra"}>
            <CrossCon>
              <CrossIco src={Cross} alt="Close" />
            </CrossCon>
          </Link>
          <TimmerCon>{formatTime()}</TimmerCon>
          <div onClick={togglePlay}>
            <CrossIco src={Phone} alt="Phone" />
          </div>
        </BreathTop>
        <AnimatedCircle breathing={breathing} sessionStarted={sessionStarted}>
          <ContentHolder>
            <BreathMid>
              <ChillImg src={Chill} alt="Chill" />
            </BreathMid>
          </ContentHolder>
        </AnimatedCircle>
        <Dis>
          <BreathBot breathing={breathing}>{breathing}</BreathBot>
          {!sessionStarted && (
            <FlatButton onClick={startSession}>ابدا</FlatButton>
          )}
        </Dis>
        <BreathLast>
          <FlatRight onClick={togglePlay}>
            <CrossIco src={AudioIcon} alt="Toggle Audio" />
          </FlatRight>
          <FlatLeft>
            <CrossIco src={Phone} alt="Phone" />
          </FlatLeft>
        </BreathLast>
      </BreathCon>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onRestart={restartSession}
        onNavigate={exploreMore}
      />
    </BreathWra>
  );
};

export default Breath;
