import React, { useState, useEffect, useRef } from "react";
import {
  BottCen,
  BottCon,
  BottIco,
  BottRig,
  BottTop,
  BottWra,
  CircleDiv,
  CircleTit,
  Circleimg,
  MedMidd,
  MedSub,
  MedTop,
  MedTopImg,
  MedWra,
  StyledLink,
} from "./Meditate.elements";
import Charcter from "../../assets/charcter.png";
import Section from "../../assets/section.png";

import { Link } from "react-router-dom";

const Meditate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MedWra>
        <MedTop>
          <MedTopImg src={Charcter} alt="" />
        </MedTop>
        <MedMidd>
          <MedSub>
            <StyledLink to={"/extra/breath"}>
              <CircleDiv>
                <Circleimg src={Section} alt="" />
                <CircleTit>تنفس</CircleTit>
              </CircleDiv>
            </StyledLink>
            <StyledLink to={"/extra/educate"}>
              <CircleDiv>
                <Circleimg src={Section} alt="" />
                <CircleTit>تعلم</CircleTit>
              </CircleDiv>
            </StyledLink>
          </MedSub>
          <MedSub>
            <StyledLink to={"/extra/sleep"}>
              <CircleDiv>
                <Circleimg src={Section} alt="" />
                <CircleTit>النوم</CircleTit>
              </CircleDiv>
            </StyledLink>
            <StyledLink to={"/extra/fantasy"}>
              <CircleDiv>
                <Circleimg src={Section} alt="" />
                <CircleTit>التخيل</CircleTit>
              </CircleDiv>
            </StyledLink>
          </MedSub>
        </MedMidd>
      </MedWra>
    </>
  );
};

export default Meditate;
