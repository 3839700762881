const statements = [
  {
    id: 1,
    text: " تكوّن أصدقاء جدد بشكل دوري",
  },
  {
    id: 2,
    text: "تقضي الكثير من وقت فراغك في تعلم مواضيع عشوائية تثير اهتمامك",
  },
  {
    id: 3,
    text: "مشاهدة الآخرين وهم يبكون تجعلك تشعر أنك تريد البكاء أيضًا",
  },
  {
    id: 4,
    text: "غالبًا ما تصنع خطة احتياطية لخطة احتياطية",
  },
  {
    id: 5,
    text: "عادةً ما تبقى هادئًا، حتى تحت ضغط كبير",
  },
  {
    id: 6,
    text: "في الفعاليات الاجتماعية، نادرًا ما تحاول تقديم نفسك لأشخاص جدد وغالبًا ما تتحدث إلى الأشخاص الذين تعرفهم بالفعل",
  },
];

export default statements;
