const future2 = [
  {
    id: 1,
    text: "تعليم المهارات الاجتماعية للأطفال ذوي الإعاقة ",
  },
  {
    id: 2,
    text: "تعليم البالغين القراءة ",
  },
  {
    id: 3,
    text: "تنسيق مؤتمر تجاري",
  },
  {
    id: 4,
    text: "وضع استراتيجية تسويقية لشركة جديدة",
  },
  {
    id: 5,
    text: "تتبع النفقات الشهرية لشركة",
  },
  {
    id: 6,
    text: "مراجعة السجلات المالية للتأكد من دقتها",
  },
];

export default future2;
