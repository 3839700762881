const future = [
  {
    id: 1,
    text: "تفحص السقف بحثًا عن التسريبات",
  },
  {
    id: 2,
    text: "استخدم الآلات الدقيقة لبناء قطع معدنية مخصصة",
  },
  {
    id: 3,
    text: "تحليل بنية الجزيئات",
  },
  {
    id: 4,
    text: "إجراء التجارب العلمية",
  },
  {
    id: 5,
    text: "تصميم غلاف مجلة",
  },
  {
    id: 6,
    text: "رسم بورتريه",
  },
];

export default future;
