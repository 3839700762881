const statements2 = [
  {
    id: 1,
    text: "تفضل إنهاء مشروع واحد بالكامل قبل البدء بآخر",
  },
  {
    id: 2,
    text: "أنت شخص حساس جداً",
  },
  {
    id: 3,
    text: "تحب استخدام أدوات التنظيم مثل الجداول الزمنية والقوائم",
  },
  {
    id: 4,
    text: "حتى الاخطاء الصغيرة يمكن تجعلك تشك في قدراتك ومعرفتك العامة",
  },
  {
    id: 5,
    text: "تشعر بالراحة عند الاقتراب من شخص تجده مثيراً للاهتمام وبدء محادثة",
  },
  {
    id: 6,
    text: " لست مهتماً جداً بمناقشة التفسيرات والتحليلات المختلفة للأعمال الإبداعية",
  },
];

export default statements2;
