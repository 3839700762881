import React from "react";
import { NavTech, Personality } from "../components";

const Personal = () => {
  return (
    <>
      <NavTech />
      <Personality />
    </>
  );
};

export default Personal;
