const statements2 = [
  {
    id: 1,
    text: "تستمتع بمشاهدة الناس يتجادلون",
  },
  {
    id: 2,
    text: "تميل إلى تجنب جلب الانتباه إلى نفسك",
  },
  {
    id: 3,
    text: "مزاجك يمكن أن يتغير بسرعة كبيرة",
  },
  {
    id: 4,
    text: "تفقد الصبر مع الأشخاص الذين ليسوا مثلك في الكفاءة",
  },
  {
    id: 5,
    text: "غالبًا ما تنتهي بعمل الأشياء في آخر لحظة ممكنة",
  },
  {
    id: 6,
    text: "لطالما كنت مفتونًا بسؤال ماذا يحدث بعد الموت، إذا كان هناك أي شيء",
  },
];

export default statements2;
