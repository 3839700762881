const future4 = [
  {
    id: 1,
    text: "تركيب خزائن المطبخ",
  },
  {
    id: 2,
    text: "النظر إلى الخلايا من خلال المجهر",
  },
  {
    id: 3,
    text: "العمل في مختبر كيمياء",
  },
  {
    id: 4,
    text: "كتابة قصيدة",
  },
  {
    id: 5,
    text: "كتابة سيناريو لبرنامج تلفزيوني",
  },
  {
    id: 6,
    text: "تقديم الاستشارة لشخص يعاني من الاكتئاب",
  },
];

export default future4;
