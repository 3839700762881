import { NavTech, Resume } from "../components";

const Something = () => {
  return (
    <>
      <NavTech />
      <Resume />
    </>
  );
};

export default Something;
