const statements3 = [
  {
    id: 1,
    text: "تشعر انك أكثر ميلاً لاتباع عقلك عن قلبك",
  },
  {
    id: 2,
    text: "عادة ما تفضل فعل ما يحلو لك في أي لحظة بدلاً من التخطيط لروتين يومي معين",
  },
  {
    id: 3,
    text: "نادراً ما تقلق بشأن ما إذا كنت تترك انطباعاً جيداً على الأشخاص الذين تقابلهم",
  },
  {
    id: 4,
    text: "تستمتع بالمشاركة في الأنشطة الجماعية",
  },
  {
    id: 5,
    text: "تحب الكتب والأفلام التي تجعلك تأتي بتفسيرك الخاص للنهاية",
  },
  {
    id: 6,
    text: "سعادتك تأتي أكثر من مساعدة الآخرين على تحقيق أشياء أكثر من إنجازاتك الشخصية",
  },
];

export default statements3;
