import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export const StyledLink = styled(Link)`
  text-decoration: none; // Removes underline
  color: inherit; // Optional: Inherits color from parent to maintain consistency
`;

export const MedWra = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  background-color: #f8f9fe;
  position: relative;
`;
export const MedTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;
export const MedTopImg = styled.img`
  width: 200px;
`;
export const MedMidd = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;

  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`;
export const MedSub = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const CircleDiv = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;
export const Circleimg = styled.img`
  width: 85%;
  height: 85%;
  border: 3px solid #073c46;
  border-radius: 50%;
`;
export const CircleTit = styled.div`
  font-size: 18px;
  color: gray;
`;

export const BottWra = styled.div`
  width: 100%;
  background-color: #ffffff; // Fixed hex color code with six characters
  border-radius: 5px;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    // Subtle shadow above the element
    0 -2px 4px -1px rgba(
        0,
        0,
        0,
        0.06
      ); // Layering multiple shadows for a smoother effect
`;
export const BottCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
export const BottTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BottCen = styled.img`
  width: 100px;
  border: 3 solid #073c46;
`;
export const BottRig = styled.img`
  width: 50px;
`;
export const BottIco = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
