const future3 = [
  {
    id: 1,
    text: "تركيب أرضية خشبية",
  },
  {
    id: 2,
    text: "إصلاح نظام تكييف الهواء",
  },
  {
    id: 3,
    text: "بحث خصائص الطاقة النووية",
  },
  {
    id: 4,
    text: "بحث دواء جديد",
  },
  {
    id: 5,
    text: "تصميم بطاقة تهنئة",
  },
  {
    id: 6,
    text: "رسم كتاب للأطفال",
  },
];

export default future3;
