import React from "react";
import { Career, NavTech, Social } from "../components";

const Job = () => {
  return (
    <>
      <NavTech />
      <Career />
    </>
  );
};

export default Job;
