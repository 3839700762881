import React from "react";
import { NavTech, SignForm } from "../components";

const SignUp = () => {
  return (
    <>
      <NavTech />
      <SignForm />
    </>
  );
};

export default SignUp;
