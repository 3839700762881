const statements4 = [
  {
    id: 1,
    text: "تهتم بالكثير من الأشياء لدرجة انك تجد صعوبة في اختيار ما يجب أن تجربه بعد ذلك",
  },
  {
    id: 2,
    text: "تشعر انك عرضة للقلق بأن الأمور قد تأخذ منعطفًا للأسوأ.",
  },
  {
    id: 3,
    text: " تتجنب أدوار القيادة في المواقف الاجتماعية",
  },
  {
    id: 4,
    text: "لا تشعر انك من النوع الفني ",
  },
  {
    id: 5,
    text: " تعتقد أن العالم سيكون مكانًا أفضل إذا اعتمد الناس أكثر على العقلانية وأقل على مشاعرهم. ",
  },
  {
    id: 6,
    text: " تفضل أن تقوم بأعمالك المنزلية قبل أن تسمح لنفسك بالاسترخاء.",
  },
];

export default statements4;
