const future5 = [
  {
    id: 1,
    text: "توظيف وفصل الموظفين",
  },
  {
    id: 2,
    text: "إغلاق صفقة تجارية هامة",
  },
  {
    id: 3,
    text: "حساب تكلفة مطالبة التأمين",
  },
  {
    id: 4,
    text: "بناء منازل",
  },
  {
    id: 5,
    text: "فحص الطقس للتلوث",
  },
  {
    id: 6,
    text: "تصميم لوحات الاعلانات",
  },
];

export default future5;
