const slides = [
  {
    text: "اكتشف نفسك من خلال اختبار شخصيتنا المميز. هذا الاختبار صُمم ليساعدك على فهم جوانب شخصيتك بشكل أعمق وأكثر دقة. ستتعرف على ميولك، قوتك، وكيفية تفاعلك مع العالم من حولك. ابدأ رحلتك نحو الوعي الذاتي والنمو الشخصي اليوم",
    audioSrc:
      "https://alsallum.s3.eu-north-1.amazonaws.com/ElevenLabs+first.mp3",
    label: "طريقة التوظيف: في ٣٠ - ٦٠ ثانية",
  },
  {
    text: "تعرف على أنماط الشخصيات المختلفة وكيف تؤثر في طريقة تعاملك مع الحياة اليومية. سواء كنت من النوع الحماسي، التحليلي، الودود أو العملي، كل شخصية لها قوتها وتحدياتها. اكتشف الخصائص والمهارات الفريدة لشخصيتك",
    audioSrc: "https://alsallum.s3.eu-north-1.amazonaws.com/ElevenLabs+two.mp3",
    label: "للمستشفيات والعيادات:  في ٣٠ - ٦٠ ثانية",
  },
  {
    text: "استفد من خدماتنا المصممة لمساعدتك على تحسين فهمك لشخصيتك وتطوير ذاتك. من خلال أدواتنا التفاعلية والإرشادات الشخصية، نوجهك لاكتشاف طرق جديدة لتحقيق التوازن والرضا في حياتك",
    audioSrc:
      "https://alsallum.s3.eu-north-1.amazonaws.com/ElevenLabs+third.mp3",
    label: "تعرف على خدماتنا: في ٣٠ - ٦٠ ثانية",
  },
  {
    text: "تعرف على خططنا المرنة ونظام الأسعار الذي يتناسب مع احتياجاتك. نقدم لك خيارات متعددة تتراوح بين الوصول الكامل لجميع مواردنا وخدماتنا إلى خيارات أكثر تحديدًا تتناسب مع اهتماماتك الشخصية",
    audioSrc:
      "https://alsallum.s3.eu-north-1.amazonaws.com/ElevenLabs+four.mp3",
    label: "تعرف على اسعارنا: في ٣٠ - ٦٠ ثانية",
  },
  {
    text: "اكتشف مسارك نحو النمو الشخصي والتطور المستمر مع اختبارات الشخصية. سنقدم لك استراتيجيات ونصائح مصممة خصيصاً لتعزيز نقاط القوة في شخصيتك ومعالجة التحديات. ابدأ رحلتك نحو تحقيق إمكاناتك الكاملة وعيش حياة أكثر إشباعاً وسعادة",
    audioSrc:
      "https://alsallum.s3.eu-north-1.amazonaws.com/ElevenLabs+five.mp3",
    label: "اعرف طريقة التقديم: في ٣٠ - ٦٠ ثانية",
  },
];

export default slides;
